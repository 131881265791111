@import '../var';

.ui-datepicker{
	box-shadow: 0 2px 26px -10px rgba(0, 0, 0, 0.4);
	border-radius: 0px;
	&-header{
		border: none;
		font-weight: normal;
		color: $main;
		padding: 10px 0;
		& .ui-datepicker-prev{
			right: 0;
			& .ui-icon{
				background: none;
			}
			&:after{
				content: "\003C";
				color: $main;
				font-size: 11px;
				padding-left: 10px;
			}
			&:hover{
				background: #FFF;
				cursor: pointer;
				border-color: #fff;
			}
		}
		& .ui-datepicker-next{
			right: 0;
			& .ui-icon{
				background: none;
			}
			&:after{
				content: "\003E";
				color: $main;
				font-size: 11px;
				padding-left: 10px;
			}
			&:hover{
				position: absolute;
				right: 1px;
				background: #FFF;
				cursor: pointer;
				border-color: #fff;
			}
		}
	}
	&-calendar{
		border: none;
		& table{
			border: none;
			
		}
	}
	& table{
		border: none;
		& thead{
			background-color: #f5f6fa;
			border: 1px solid #e8e9eb;
			color: #3A424F;
			font-size: 12px;
			& th{
				font-weight: normal;
			}
		}
		& tbody{
			& tr{
				& .ui-state-default{
					border: none;
					background: #FFF;
					color: $main;
					&:hover{
						box-shadow: inset 0 0 0 1px $main;
						cursor: pointer;
						border-radius: 50%;
					}
				}
				& .ui-state-highlight{
					background: $main;
					color: #fff;
					border-radius: 50%;
					border: none;
				}
				& a{
					text-align: center;
					padding: 7px 4px;
				}
			}
		}
	}
}
.ui-widget-header{
	background: none !important;
}