@import 'var';
@import 'controls/checkbox';
@import 'controls/textarea';
@import 'controls/input';
@import 'controls/select';
@import 'controls/accordion';
@import 'controls/tag';
@import 'controls/buttons';
@import 'controls/icons';
@import 'controls/status';
@import 'controls/stars';
@import 'controls/notice';
@import 'controls/pagination';
@import 'controls/autocomplete';
@import 'controls/datepicker';
@import 'controls/tours_form_image';


.block{
    background: #fff;
    padding: 0;
    margin-bottom: 13px;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);

    &-title{
        background: $main;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dee2ed;
        transition: $transition;
        align-items: center;
        height: 48px;
        line-height: 100%;
        margin: 0;

        &__text{
            padding: 0 26px;
            color: #fff;
            font-size: 18px;
            font-weight: 300;
            line-height:100%;
        }

        .material-icons{
            color: #80a8ff;
        }
    }

    &-button{
        display: flex;
        flex: 0 0 100%;
        height: 48px;
        text-align: center;
        background-color: #f5f6fa;
        color: $main;
        font-weight: 100;
        font-size: 16px;
        align-items: center;
        position: relative;
        justify-content: flex-end;

        // .button{
        //     width: auto;
        //     height: 33px;
        //     line-height: 33px;
        //     margin-right: 5px;
        //     padding: 0 25px;
        //     font-size: 13px;
        //     text-transform: uppercase;
        // }
        
        .button{
            // display: none;

            float: right;
            width: auto;
            border-radius: 2px;
            line-height: 34px;
            text-transform: uppercase;
            font-weight: 100;
            margin-right: 7px;
            margin-bottom: 0.5px;

            height: 36px;
            padding: 2px 55px 2px 20px;
            font-size: 14px;
            color: #FFF;
            position: relative;
            background-color: $btn-blue;
            &:hover{
              background-color: rgba(76, 132, 255, 0.85);
              color: #fff;
            }

            & .material-icons{
              color: #FFF;
              border-left: 1px solid #fff;
              position: absolute;
              top: 0;
              right: 0;
              font-size: 20px;
              padding: 8px;
            }
        }
    }

    &-body{
        //transition: $transition;
    }

    &-toggle{

        transition: $transition;

        &.closed{
            transform: rotate(180deg);
        }
    }
    &.closed{
        .block-title{
            padding-bottom: 0;
            margin-bottom: 0;
            border-color: transparent;
        }
    }
}

@media only screen and (max-width: 980px) {
  .block-button{
    .button{
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 36px;
      text-indent: 9999px;
      font-size: 0;
      & .material-icons{
        border: none;
      }
    }
  }
}

.grid{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_wrap{
        flex-wrap: wrap;
        border-bottom: 1px solid #e6e8ec;

        &:nth-child(odd){
            //background-color: #f9fbff;
        }

        &:nth-child(even){
            //background-color: #f5f6fa;
        }
    }

    &-item{

        > h2{
            color: #3A424F;
            font-size: 14px;
            font-weight: 300;
            margin: 0;
            padding-left: 30px;

            &.padding{
                padding: 20px 0 20px 30px;
            }
        }

        &_full{
            min-height: 48px;
            align-items: center;
            display: flex;
            flex: 0 0 100%;

            &.bordertop{
                border-top: 1px solid #e6e8ec;
            }
            
            &.codex{
                padding-left: 50px;
                background-color: #e6e8ec;
            }
        }

        &_wis{
            flex: 0 0 100%;
            padding: 10px 50px 15px 50px;
        }

        &_title{
            align-items: center;
            display: flex;
            height: 48px;
            flex: 0 0 40%;
        }

        &_checkbox{
            height: 48px;
            align-items: center;
            display: flex;
            flex: 0 0 50%;
            text-align: right;
        }

        &_input{
            // height: 48px;
            height: auto;
            flex: 0 0 50%;


            &--text{
                text-align: right;
                color: #808080;
                height: 45px;
                flex: 1 0 auto;
                align-items: center;
                display: flex;
            }

            &--right{
                text-align: right;
            }
        }

        &_textarea{
            padding: 5px 0;
            min-height: 48px;
            flex: 0 0 50%;
        }

        &_multiselect{
            padding: 5px 0;
            min-height: 48px;
            flex: 0 0 100%;
        }

        &_image{
            padding: 5px 0;
            min-height: 48px;
            flex: 0 0 50%;

            .hint-block{
                display: none;
            }
        }

        &_select{
            height: 48px;
            flex: 1 0 auto;
            max-width: calc(100% - 286px);
        }

        &_category{
            flex: 0 0 200px;
        }

        &_position{
            flex: 0 1 200px;

            &-add,
            &-add:active,
            &-add:focus{
                color: #3271FE;
                font-size: 16px;
                font-weight: 300;
                text-transform: uppercase;
                margin-left: 45px;
                display: inline-block;
            }

            .input{
                width: 50px;
                text-align: right;
                color: #3A424F;
                font-size: 14px;
                font-weight: 300;
                padding-right: 12px;
            }
        }

        &_hint{
            & .material-icons{
                color: #80A8FF;
                font-size: 18px;
                cursor: default;
            }
            align-items: center;
            display: flex;
            height: 42px;
            flex: 0 0 10%;
            font-size: 11px;
            font-weight: 300;
            color: #8B93A6;
            text-align: center;
            position: relative;
            justify-content: center;
            
            a{
                color: $main;
                &:hover{
                    text-decoration: underline;
                }
            }

            &-info{
                width: 16px;
                height: 16px;
                background: url("../img/hint_info.png") 0 0 no-repeat;
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-8px);
            }
        }

        &_box{
            align-items: center;
            display: flex;
            height: 42px;
            flex: 0 0 10%;
            font-size: 11px;
            font-weight: 300;
            color: #8B93A6;
            text-align: center;
            position: relative;
            justify-content: flex-start;
        }
    }

    &_title{
        margin: 0 -50px;
        background-color: #F5F6FA;
        padding: 16px 50px;
    }

    &_header{
        padding: 0 0 14px;
    }

    &-wrapper{
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 20px;
    }

    &-body{
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .grid{
            flex-grow: 1;
            margin-bottom: 14px;

            .grid-item_position {
                flex: 0 0 231px;
                display: flex;

                .input{
                    display: inline-block;
                }

                .material-icons{
                    color: #FC738D;
                }

                .icon-btn{
                    transform: translateX(34px);
                }

                .icon-btn:after{
                    border-color: #FC738D;
                }
            }

            .grid-item_category{
                color: $main;
                font-size: 16px;
                font-weight: 300;
            }
        }
    }
    &-sidebar{
        flex: 0 0 220px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 200px;

        &__link{
            color: $main;
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 20px;
            &.icon-btn{
                transform: translateX(-34px);
            }
        }

        &__button{
            width: 100%;
            margin-top: 55px;
        }
    }
}

.codex-editor{
    width: 100%;
    max-width: 700px;
}

.slimScrollDiv{
    width: 100% !important;
}




















