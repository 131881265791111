@charset "UTF-8";
.checkbox {
  width: 100px;
  height: 30px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
}

.checkbox input {
  visibility: hidden;
  position: absolute;
  opacity: 0;
}

.checkbox input:checked + .checkbox__switcher {
  background-color: #4c84ff;
  box-shadow: inset 0 2px 4px rgba(58, 66, 79, 0.3);
}

.checkbox input:disabled + .checkbox__switcher {
  background-color: #F5F6FA;
  box-shadow: none;
  box-shadow: 0 0 0 1px #DEE2ED inset;
  cursor: not-allowed;
}

.checkbox input:disabled + .checkbox__switcher:after {
  box-shadow: none;
  box-shadow: 0 0 0 1px #DEE2ED inset;
  background: transparent;
  width: 24px !important;
}

.checkbox input:checked + .checkbox__switcher:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.checkbox input:checked + .checkbox__switcher .checkbox__text:before {
  opacity: 0;
}

.checkbox input:checked + .checkbox__switcher .checkbox__text:after {
  opacity: 1;
}

.checkbox input:disabled + .checkbox__switcher .checkbox__text {
  opacity: 0;
}

.checkbox input + .checkbox__switcher .checkbox__text:after {
  opacity: 0;
}

.checkbox__switcher {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 15px;
  background-color: #F5F6FA;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.checkbox__switcher:after {
  display: block;
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #FFF;
  box-shadow: 0 4px 8px rgba(58, 66, 79, 0.3);
  border-radius: 50%;
  left: 5px;
  top: 5px;
  transition: all 0.2s ease-in-out;
}

.checkbox:active .checkbox__switcher:after {
  width: 40px;
}

.checkbox__text {
  width: 100%;
  height: 100%;
  position: relative;
}

.checkbox__text:before, .checkbox__text:after {
  display: block;
  position: absolute;
  top: 7px;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
}

.checkbox__text:before {
  content: 'Нет';
  right: 27px;
  color: #BFC5D1;
}

.checkbox__text:after {
  content: 'Да';
  left: 35px;
  color: #fff;
}

.checkbox-group {
  display: flex;
}

.checkbox-group .checkbox {
  height: 28px;
  width: auto;
  display: flex;
  align-items: center;
  margin-left: -1px;
}

.checkbox-group .checkbox:first-child .checkbox__text {
  margin: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.checkbox-group .checkbox:last-child .checkbox__text {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.checkbox-group .checkbox__text {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  padding: 0 20px;
  color: #8B93A6;
  transition: all 0.2s ease-in-out;
  background-color: #F5F6FA;
  border: 1px solid #DEE2ED;
}

.checkbox-group .checkbox__text:before, .checkbox-group .checkbox__text:after {
  display: none;
}

.checkbox-group .checkbox input:checked + .checkbox__text {
  color: #fff;
  background-color: #4c84ff;
  box-shadow: inset 0 2px 4px rgba(58, 66, 79, 0.3);
  border: 1px solid #4c84ff;
}

.field-travelclients-sex,
#travelclients-sex,
.field-travelorders-visa,
#travelorders-visa {
  display: flex;
  margin: 0;
}

.textarea {
  resize: none;
  width: 100% !important;
  color: #3A424F;
  font-size: 14px;
  font-weight: 300;
  height: 100%;
  max-height: 100%;
  min-height: 100px !important;
  background-color: #f6f9ff;
  border-radius: 0;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #f6f9ff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.textarea:focus {
  background: #f6f9ff;
  font-size: 14px;
  box-shadow: inset 0px -3px 0 0 #4c84ff;
}

.note-editor.note-frame {
  margin: 0 50px;
}

.note-editor .note-btn-group.note-color, .note-editor .note-btn-group.note-fontname {
  display: none;
}

.mce-btn,
.mce-panel {
  background-color: inherit !important;
}

.mce-tinymce {
  box-shadow: 0 0 0 !important;
}

.example-content-description {
  opacity: 0;
}

.grid-item_full > .codex-editor {
  border-top: 1px solid #e6e8ec;
  background-color: #fbfbfb;
}

.grid-item_full > .codex-editor .codex-editor__redactor {
  padding-left: 10px;
}

.input {
  padding: 0 8px;
  height: 30px;
  width: 100%;
  border: 1px solid #f6f9ff;
  font-size: 14px;
  font-weight: 300;
  transition: all 0.2s ease-in-out;
  color: #3A424F;
  background-color: #f6f9ff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.input:focus {
  font-size: 14px;
  box-shadow: inset 0px -2px 0 0 #4c84ff;
}

.input_error {
  color: #fc738d;
  border-color: #fc738d;
}

.input_error:focus {
  color: #3A424F;
  border-color: #4c84ff;
}

.input:disabled {
  border-color: #dee2ed;
  background: transparent;
  color: #dee2ed;
  cursor: not-allowed;
}

.input_success, .input_success:disabled {
  background: #f5f6fa;
  color: #4c84ff;
  border-color: #4c84ff;
}

.input_bordered {
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  border-radius: 2px;
  height: 30px;
  font-size: 14px;
  font-weight: 100;
}

.input_bordered:disabled {
  cursor: not-allowed;
  color: #ccc !important;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 0;
}

.input_bordered--min {
  text-align: center;
  width: 105px;
}

.hasDatepicker {
  background-color: #f6f9ff;
  border: 1px solid #f6f9ff;
  border-radius: 2px;
  height: 30px;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 100;
  width: 105px;
}

.select {
  height: 30px;
  line-height: 29px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0 25px 0 15px;
  position: relative;
  font-weight: 300;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.select-inner {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.select .material-icons {
  position: absolute;
  top: 8px;
  right: 4px;
  color: #3A424F;
  font-size: 18px;
}

.select .dropdown {
  right: -1px;
  top: 29px;
  width: calc(100% + 2px);
  border: 1px solid #BFC5D1;
  border-top: 0;
  transform: translateX(0) translateY(-20px);
  font-size: 12px;
}

.select .dropdown:after {
  display: none;
}

.select .dropdown-item {
  height: 30px;
  color: #3A424F !important;
}

.select .dropdown-item span {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-left: 0;
}

.select .dropdown-item:hover {
  background: #dee2ed;
}

.select_pages {
  width: 80px;
}

.select_item {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  background: #f5f6fa;
  border: 1px solid #f6f9ff;
}

.select_item.vis {
  border-color: #80a8ff;
}

.select_item .material-icons {
  color: #80a8ff;
}

.select_empty {
  background: #f6f9ff;
}

.select_empty .select-inner {
  color: #BFC5D1;
  font-size: 14px;
  font-weight: 300;
}

.grid-item_multiselect {
  padding: 5px 50px !important;
}

.select2-selection {
  outline: thin;
}

.select2-results__options {
  font-weight: 100 !important;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

.select2-container {
  display: block;
  width: auto !important;
}

.select2-container--focus {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
}

.select2-dropdown {
  left: 0 !important;
  border: 1px solid #4c84ff !important;
  border-top: none !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  background-color: #f6f9ff !important;
}

.select2-container--default .select2-selection--multiple {
  min-height: 70px;
  background-color: #f6f9ff !important;
  border-color: #4c84ff;
  border-radius: 1px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #4c84ff !important;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none !important;
}

.select2-selection__choice {
  height: 36px;
  background-color: #FFF !important;
  border: 1px solid #C4CAD5 !important;
  padding: 8px 15px 10px 10px !important;
  color: #4c84ff;
  margin: 6px !important;
  border-radius: 1px !important;
}

.select2-selection__choice__remove {
  margin-right: 15px !important;
  color: #C4CAD5 !important;
}

.select2-selection__choice__remove:hover {
  color: #9fa4b5 !important;
}

.select2-container--default .select2-results__option {
  background-color: #f6f9ff !important;
}

.select2-container--default .select2-results__option--highlighted {
  background-color: #4c84ff !important;
}

.select2-container-multi {
  display: block;
  width: auto !important;
  margin: 0 50px;
}

.select2-container-multi .select2-choices {
  min-height: 70px;
  background-color: #F5F6FA !important;
  background-image: none !important;
  border-color: #4c84ff !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
  height: 36px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #C4CAD5;
  border-radius: 1px;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 30px;
  color: #4c84ff;
  font-size: 14px;
  font-weight: 300;
  margin: 6px;
  box-shadow: 0 0 0;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  color: #FC738D;
  left: 10px;
  top: 10px;
}

.select2-container-multi .select2-choices .select2-search-choice .select2-search-choice-close:after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 9;
  border: 0;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.grid-item_select .select2-container--default {
  height: 30px !important;
  line-height: 29px !important;
  cursor: pointer !important;
  padding-right: 25px !important;
  position: relative !important;
  font-weight: 300 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
  border-radius: 0 !important;
  background-color: #f6f9ff !important;
  border: 1px solid #f6f9ff !important;
  border-bottom: none !important;
}

.grid-item_select .select2-container--open {
  border-color: #4c84ff !important;
}

.grid-item_select .select2-container--open .select2-selection__arrow b {
  border-color: transparent transparent #80a8ff transparent !important;
}

.grid-item_select .select2-selection--single {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  border: none !important;
  background-color: transparent !important;
}

.grid-item_select .select2-selection__arrow b {
  border-color: #80a8ff transparent transparent transparent !important;
}

.select2-drop {
  background-color: #f5f6fa !important;
}

.field-tours-cities .help-block,
.field-tours-categories .help-block {
  margin: 0;
}

.accordion__title {
  background-color: #dee2ed;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  height: 46px;
  border-bottom: 1px solid #dee2ed;
  position: relative;
  min-height: 50px;
}

.accordion__title-toggle {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: 10px;
  display: flex;
}

.accordion__title-toggle_closed {
  display: none;
}

.accordion__title-toggle .material-icons {
  color: #80A8FF;
}

.accordion__title-text {
  color: #4c84ff;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0 0 0 44px;
  flex: 0 0 80%;
}

.accordion__title-text--clear {
  margin-left: 20px;
}

.accordion.closed .accordion__title-toggle {
  display: none;
}

.accordion.closed .accordion__title-toggle_closed {
  display: flex;
}

.accordion__grid {
  padding: 12px 80px 12px 50px;
  border-bottom: 1px solid #dee2ed;
  display: flex;
  align-items: flex-start;
}

.accordion__grid-desc {
  flex: 0 0 286px;
  color: #3A424F;
  font-size: 16px;
  font-weight: 300;
  padding: 7px 50px 0 0;
  line-height: 140%;
}

.accordion__grid-desc_sub {
  color: #8B93A6;
  font-size: 12px;
  display: block;
  margin-top: 10px;
  line-height: 140%;
}

.accordion__grid-select {
  width: 100%;
}

.accordion__grid-val {
  flex: 1 0 auto;
  max-width: calc(100% - 286px);
}

.accordion__grid-val .btn-radio {
  margin: 10px 0;
}

.accordion__grid_between {
  justify-content: space-between;
}

.accordion__grid_between .accordion__grid-desc, .accordion__grid_between .accordion__grid-val {
  flex: 0 0 auto;
  max-width: 100%;
}

.accordion .grid_wrap {
  flex-wrap: wrap;
}

.tag {
  height: 36px;
  background-color: #F5F6FA;
  border: 1px solid #C4CAD5;
  border-radius: 1px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #4c84ff;
  font-size: 14px;
  font-weight: 300;
  margin: 6px 12px;
}

.tag__wrap {
  flex-wrap: wrap;
  flex-direction: row;
}

.tag__name {
  cursor: default;
}

.tag__name:before {
  display: none;
}

.tag .icon-btn .material-icons {
  font-size: 15px;
  color: #FC738D;
}

.tag .icon-btn:after {
  border-color: #FC738D;
}

.tag .icon-btn:hover::after {
  width: 26px;
  height: 26px;
}

.material-button {
  background: #f6f9ff;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: #4c84ff;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
}

.material-button--danger {
  color: #f00;
}

.block-title .button {
  float: right;
  width: auto;
  border-radius: 2px;
  line-height: 34px;
  text-transform: uppercase;
  font-weight: 100;
  margin-right: 7px;
  margin-bottom: 0.5px;
  height: 36px;
  padding: 2px 55px 2px 20px;
  font-size: 14px;
  color: #FFF;
  position: relative;
  background-color: #82a9ff;
  border: none;
  -webkit-appearance: none;
}

.block-title .button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: #fff !important;
}

.block-title .button .material-icons {
  color: #FFF;
  border-left: 1px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 8px;
}

.block-title .button--active {
  display: block;
}

.block-body .btn {
  float: right;
  width: auto;
  border-radius: 2px;
  line-height: 34px;
  text-transform: uppercase;
  font-weight: 100;
  margin-right: 7px;
  margin-bottom: 0.5px;
  height: 36px;
  padding: 2px 55px 2px 20px;
  font-size: 14px;
  color: #FFF;
  position: relative;
  background-color: #82a9ff;
  border: none;
  -webkit-appearance: none;
}

.block-body .btn:hover {
  background-color: rgba(130, 169, 255, 0.8) !important;
  color: #fff !important;
  border: none !important;
}

.block-body .btn .material-icons {
  color: #FFF;
  border-left: 1px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 8px;
}

.block-body .btn .hidden {
  display: inline-block !important;
}

.block-body .btn .glyphicon {
  position: absolute;
  left: 99.5px !important;
  right: 0 !important;
  padding: 11px;
  border-left: 1px solid #fff;
  top: 0;
  cursor: pointer;
}

.block-body .btn-pink {
  background-color: #f6849e !important;
}

.block-body .btn-pink:hover {
  background-color: rgba(246, 132, 158, 0.8) !important;
}

.block-body .btn-danger {
  float: none !important;
  background-color: #f6849e !important;
  margin-bottom: 7px;
}

.block-body .btn-danger:hover {
  background-color: rgba(246, 132, 158, 0.8) !important;
}

.block-body .btn-danger .glyphicon {
  left: 87.5px !important;
  width: 36px;
}

.block-body .btn-primary {
  float: none !important;
}

.block-body .btn-primary .glyphicon {
  left: 87.5px !important;
  width: 36px;
}

@media only screen and (max-width: 980px) {
  .block-title .button {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    text-indent: 9999px;
    font-size: 0;
  }
  .block-title .button .material-icons {
    border: none;
  }
  .block-body .btn {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    text-indent: 9999px;
    font-size: 0;
  }
  .block-body .btn .material-icons {
    border: none;
  }
  .block-body .btn .glyphicon {
    left: 0 !important;
    right: 0 !important;
    padding: 11px;
    border-left: none;
    font-size: 14px;
    text-indent: 0;
  }
  .block-body .btn-danger {
    text-indent: 0;
    font-size: 14px;
  }
  .block-body .btn-danger .glyphicon {
    background-color: #f6849e;
    margin-left: 0;
  }
  .block-body .btn-primary {
    text-indent: 0;
    font-size: 14px;
  }
  .block-body .btn-primary .glyphicon {
    background-color: #82a9ff;
    margin-left: 0;
  }
}

.status-circle-dividing:before,
.status-circle-bright_blue:before,
.status-circle-purple:before,
.status-circle-green:before,
.status-circle-skyblue:before,
.status-circle-pink:before,
.status-circle-brown:before,
.status-circle-orange:before {
  content: "\25CF";
  font-size: 16px;
  margin: 0 4px;
  position: relative;
  top: 0.5px;
  z-index: 10;
}

.status-circle-dividing:before {
  color: #e4edff;
}

.status-circle-orange:before {
  color: #ff8871;
}

.status-circle-bright_blue:before {
  color: #78d9da;
}

.status-circle-purple:before {
  color: #aa8aff;
}

.status-circle-green:before {
  color: #94d674;
}

.status-circle-skyblue:before {
  color: #9caed7;
}

.status-circle-pink:before {
  color: #f6849e;
}

.status-circle-brown:before {
  color: #a1968b;
}

.input-stars {
  display: flex;
  padding-top: 12px;
}

.input-stars .material-icons {
  width: 30px;
  color: #dee2ed;
}

.notice-success {
  display: flex;
  flex: 0 0 100%;
  height: 48px;
  text-align: center;
  background-color: #f5f6fa;
  color: #4c84ff;
  font-weight: 100;
  font-size: 16px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.notice-success__close {
  position: absolute;
  right: 12px;
  top: 12px;
}

.pagination {
  display: flex;
  margin: 30px 0;
  border-radius: 0;
  font-weight: 100;
}

.pagination li {
  display: block;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.pagination li a {
  min-width: 50px;
  display: block;
  background: #fff;
  color: #4394FB;
  text-align: center;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #dee2ed;
  border-radius: 0 !important;
}

.pagination li span {
  border-radius: 0 !important;
}

.pagination li.active a {
  background: #4c84ff;
  color: #fff;
}

.pagination li.active a:hover {
  background: #4c84ff;
  color: #fff;
}

.pagination-item {
  width: 50px;
  height: 24px;
  line-height: 24px;
  display: block;
  background: #fff;
  color: #4394FB;
  text-align: center;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.pagination-item__disabled {
  background: transparent;
  cursor: default;
  color: #AFB2B7;
}

.pagination-item__disabled:hover {
  background: transparent !important;
  color: #AFB2B7;
}

.pagination-item__active, .pagination-item:hover {
  background-color: #3271FE;
  color: #fff;
}

.ui-autocomplete {
  border: 0 !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  padding: 0 !important;
}

.ui-autocomplete .ui-menu-item {
  border: 0 !important;
  height: 36px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper {
  font-family: 'Roboto';
  border: 0 !important;
  height: 36px;
  padding: 0 0 0 10px;
  display: flex;
  align-items: center;
  color: #3A424F !important;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  font-weight: 100 !important;
  background-color: #f5f6fa;
  margin: 0 !important;
}

.ui-autocomplete .ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  height: 36px !important;
  padding: 0 0 0 10px !important;
  border: 0 !important;
  background-image: none !important;
  background-color: #4c84ff !important;
  color: #fff !important;
  margin: 0 !important;
}

.ui-datepicker {
  box-shadow: 0 2px 26px -10px rgba(0, 0, 0, 0.4);
  border-radius: 0px;
}

.ui-datepicker-header {
  border: none;
  font-weight: normal;
  color: #4c84ff;
  padding: 10px 0;
}

.ui-datepicker-header .ui-datepicker-prev {
  right: 0;
}

.ui-datepicker-header .ui-datepicker-prev .ui-icon {
  background: none;
}

.ui-datepicker-header .ui-datepicker-prev:after {
  content: "\003C";
  color: #4c84ff;
  font-size: 11px;
  padding-left: 10px;
}

.ui-datepicker-header .ui-datepicker-prev:hover {
  background: #FFF;
  cursor: pointer;
  border-color: #fff;
}

.ui-datepicker-header .ui-datepicker-next {
  right: 0;
}

.ui-datepicker-header .ui-datepicker-next .ui-icon {
  background: none;
}

.ui-datepicker-header .ui-datepicker-next:after {
  content: "\003E";
  color: #4c84ff;
  font-size: 11px;
  padding-left: 10px;
}

.ui-datepicker-header .ui-datepicker-next:hover {
  position: absolute;
  right: 1px;
  background: #FFF;
  cursor: pointer;
  border-color: #fff;
}

.ui-datepicker-calendar {
  border: none;
}

.ui-datepicker-calendar table {
  border: none;
}

.ui-datepicker table {
  border: none;
}

.ui-datepicker table thead {
  background-color: #f5f6fa;
  border: 1px solid #e8e9eb;
  color: #3A424F;
  font-size: 12px;
}

.ui-datepicker table thead th {
  font-weight: normal;
}

.ui-datepicker table tbody tr .ui-state-default {
  border: none;
  background: #FFF;
  color: #4c84ff;
}

.ui-datepicker table tbody tr .ui-state-default:hover {
  box-shadow: inset 0 0 0 1px #4c84ff;
  cursor: pointer;
  border-radius: 50%;
}

.ui-datepicker table tbody tr .ui-state-highlight {
  background: #4c84ff;
  color: #fff;
  border-radius: 50%;
  border: none;
}

.ui-datepicker table tbody tr a {
  text-align: center;
  padding: 7px 4px;
}

.ui-widget-header {
  background: none !important;
}

.grid-item_image .row .col-md-12 {
  display: flex;
  align-items: flex-start;
}

.grid-item_image .row .col-md-12 .btn {
  border-radius: 0 !important;
}

@media only screen and (max-width: 1100px) {
  .grid-item_image .row .col-md-12 {
    display: block;
  }
}

.block {
  background: #fff;
  padding: 0;
  margin-bottom: 13px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.block-title {
  background: #4c84ff;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dee2ed;
  transition: all 0.2s ease-in-out;
  align-items: center;
  height: 48px;
  line-height: 100%;
  margin: 0;
}

.block-title__text {
  padding: 0 26px;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  line-height: 100%;
}

.block-title .material-icons {
  color: #80a8ff;
}

.block-button {
  display: flex;
  flex: 0 0 100%;
  height: 48px;
  text-align: center;
  background-color: #f5f6fa;
  color: #4c84ff;
  font-weight: 100;
  font-size: 16px;
  align-items: center;
  position: relative;
  justify-content: flex-end;
}

.block-button .button {
  float: right;
  width: auto;
  border-radius: 2px;
  line-height: 34px;
  text-transform: uppercase;
  font-weight: 100;
  margin-right: 7px;
  margin-bottom: 0.5px;
  height: 36px;
  padding: 2px 55px 2px 20px;
  font-size: 14px;
  color: #FFF;
  position: relative;
  background-color: #82a9ff;
}

.block-button .button:hover {
  background-color: rgba(76, 132, 255, 0.85);
  color: #fff;
}

.block-button .button .material-icons {
  color: #FFF;
  border-left: 1px solid #fff;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  padding: 8px;
}

.block-toggle {
  transition: all 0.2s ease-in-out;
}

.block-toggle.closed {
  transform: rotate(180deg);
}

.block.closed .block-title {
  padding-bottom: 0;
  margin-bottom: 0;
  border-color: transparent;
}

@media only screen and (max-width: 980px) {
  .block-button .button {
    display: block;
    padding-left: 0;
    padding-right: 0;
    width: 36px;
    text-indent: 9999px;
    font-size: 0;
  }
  .block-button .button .material-icons {
    border: none;
  }
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grid_wrap {
  flex-wrap: wrap;
  border-bottom: 1px solid #e6e8ec;
}

.grid-item > h2 {
  color: #3A424F;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding-left: 30px;
}

.grid-item > h2.padding {
  padding: 20px 0 20px 30px;
}

.grid-item_full {
  min-height: 48px;
  align-items: center;
  display: flex;
  flex: 0 0 100%;
}

.grid-item_full.bordertop {
  border-top: 1px solid #e6e8ec;
}

.grid-item_full.codex {
  padding-left: 50px;
  background-color: #e6e8ec;
}

.grid-item_wis {
  flex: 0 0 100%;
  padding: 10px 50px 15px 50px;
}

.grid-item_title {
  align-items: center;
  display: flex;
  height: 48px;
  flex: 0 0 40%;
}

.grid-item_checkbox {
  height: 48px;
  align-items: center;
  display: flex;
  flex: 0 0 50%;
  text-align: right;
}

.grid-item_input {
  height: auto;
  flex: 0 0 50%;
}

.grid-item_input--text {
  text-align: right;
  color: #808080;
  height: 45px;
  flex: 1 0 auto;
  align-items: center;
  display: flex;
}

.grid-item_input--right {
  text-align: right;
}

.grid-item_textarea {
  padding: 5px 0;
  min-height: 48px;
  flex: 0 0 50%;
}

.grid-item_multiselect {
  padding: 5px 0;
  min-height: 48px;
  flex: 0 0 100%;
}

.grid-item_image {
  padding: 5px 0;
  min-height: 48px;
  flex: 0 0 50%;
}

.grid-item_image .hint-block {
  display: none;
}

.grid-item_select {
  height: 48px;
  flex: 1 0 auto;
  max-width: calc(100% - 286px);
}

.grid-item_category {
  flex: 0 0 200px;
}

.grid-item_position {
  flex: 0 1 200px;
}

.grid-item_position-add, .grid-item_position-add:active, .grid-item_position-add:focus {
  color: #3271FE;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
  margin-left: 45px;
  display: inline-block;
}

.grid-item_position .input {
  width: 50px;
  text-align: right;
  color: #3A424F;
  font-size: 14px;
  font-weight: 300;
  padding-right: 12px;
}

.grid-item_hint {
  align-items: center;
  display: flex;
  height: 42px;
  flex: 0 0 10%;
  font-size: 11px;
  font-weight: 300;
  color: #8B93A6;
  text-align: center;
  position: relative;
  justify-content: center;
}

.grid-item_hint .material-icons {
  color: #80A8FF;
  font-size: 18px;
  cursor: default;
}

.grid-item_hint a {
  color: #4c84ff;
}

.grid-item_hint a:hover {
  text-decoration: underline;
}

.grid-item_hint-info {
  width: 16px;
  height: 16px;
  background: url("../img/hint_info.png") 0 0 no-repeat;
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-8px);
}

.grid-item_box {
  align-items: center;
  display: flex;
  height: 42px;
  flex: 0 0 10%;
  font-size: 11px;
  font-weight: 300;
  color: #8B93A6;
  text-align: center;
  position: relative;
  justify-content: flex-start;
}

.grid_title {
  margin: 0 -50px;
  background-color: #F5F6FA;
  padding: 16px 50px;
}

.grid_header {
  padding: 0 0 14px;
}

.grid-wrapper {
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
}

.grid-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.grid-body .grid {
  flex-grow: 1;
  margin-bottom: 14px;
}

.grid-body .grid .grid-item_position {
  flex: 0 0 231px;
  display: flex;
}

.grid-body .grid .grid-item_position .input {
  display: inline-block;
}

.grid-body .grid .grid-item_position .material-icons {
  color: #FC738D;
}

.grid-body .grid .grid-item_position .icon-btn {
  transform: translateX(34px);
}

.grid-body .grid .grid-item_position .icon-btn:after {
  border-color: #FC738D;
}

.grid-body .grid .grid-item_category {
  color: #4c84ff;
  font-size: 16px;
  font-weight: 300;
}

.grid-sidebar {
  flex: 0 0 220px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 200px;
}

.grid-sidebar__link {
  color: #4c84ff;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;
}

.grid-sidebar__link.icon-btn {
  transform: translateX(-34px);
}

.grid-sidebar__button {
  width: 100%;
  margin-top: 55px;
}

.codex-editor {
  width: 100%;
  max-width: 700px;
}

.slimScrollDiv {
  width: 100% !important;
}
