@import '../var';

.pagination{
  display: flex;
  margin: 30px 0;
  border-radius: 0;
  font-weight:100;

  li{
    display: block;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);

    a{
      min-width: 50px;
      display: block;
      background: #fff;
      color: #4394FB;
      text-align: center;
      font-size: 14px;
      transition: $transition;
      border: 1px solid #dee2ed;
      border-radius: 0 !important;
    }

    span{
      border-radius: 0 !important;
    }

    &.active{

      a{
        background: $main;
        color: #fff;

        &:hover{
          background: $main;
          color: #fff;
        }
      }
    }
  }

  &-item{
    width: 50px;
    height: 24px;
    line-height: 24px;
    display: block;
    background: #fff;
    color: #4394FB;
    text-align: center;
    font-size: 14px;
    transition: $transition;

    &__disabled{
      background: transparent;
      cursor: default;
      color: #AFB2B7;

      &:hover{
        background: transparent !important;
        color: #AFB2B7;
      }
    }

    &__active, &:hover{
      background-color: #3271FE;
      color: #fff;
    }
  }
}