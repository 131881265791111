@import '../var';

.input{
  padding: 0 8px;
  height: 30px;
  width: 100%;
  border: 1px solid $bgcontrols;
  font-size: 14px;
  font-weight: 300;
  transition: $transition;
  color: #3A424F;
  background-color: $bgcontrols;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);

  &:focus{
    font-size: 14px;
    box-shadow: inset 0px -2px 0 0 $main;
  }

  &_error{
    color: #fc738d;
    border-color: #fc738d;

    &:focus{
      color: #3A424F;
      border-color: $main;
    }
  }

  &:disabled{
    border-color: #dee2ed;
    background: transparent;
    color: #dee2ed;
    cursor: not-allowed;
  }

  &_success,
  &_success:disabled{
    background: #f5f6fa;
    color: $main;
    border-color: $main;
  }

  &_bordered{
    background-color: $bgcontrols;
    border: 1px solid $bgcontrols;
    border-radius: 2px;
    height: 30px;
    font-size: 14px;
    font-weight: 100;

    &:disabled{
      cursor: not-allowed;
      color: #ccc !important;
      background: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 0 0;
    }

    &--min{
      text-align: center;
      width: 105px;
    }
  }
}

.hasDatepicker{
  background-color: $bgcontrols;
  border: 1px solid $bgcontrols;
  border-radius: 2px;
  height: 30px;
  padding-left: 15px;
  font-size: 14px;
  font-weight:100;
  width: 105px;
}