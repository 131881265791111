@import '../var';

.ui-autocomplete{
  border: 0 !important;
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  padding: 0 !important;

  .ui-menu-item{

    border: 0 !important;
    height: 36px !important;
    padding: 0 !important;
    margin: 0 !important;

    .ui-menu-item-wrapper{
      font-family: 'Roboto';
      border: 0 !important;
      height: 36px;
      padding: 0 0 0 10px;
      display: flex;
      align-items: center;
      color: #3A424F !important;
      transition: all 0.2s ease-in-out;
      font-size: 14px;
      font-weight: 100 !important;
      background-color: #f5f6fa;
      margin: 0 !important;

      &.ui-state-active{
        height: 36px !important;
        padding: 0 0 0 10px !important;
        border: 0 !important;
        background-image: none !important;
        background-color: $main !important;
        color: #fff !important;
        margin: 0 !important;
      }
    }
  }
}