@import '../var';

.select{
  height: 30px;
  line-height: 29px;
  // border: 1px solid #BFC5D1;
  border-radius: 2px;
  cursor: pointer;
  padding: 0 25px 0 15px;
  position: relative;
  font-weight: 300;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);

  &-inner{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  .material-icons{
    position: absolute;
    top: 8px;
    right: 4px;
    color: #3A424F;
    font-size: 18px;
  }

  .dropdown {
    right: -1px;
    top: 29px;
    width: calc(100% + 2px);
    border: 1px solid #BFC5D1;
    border-top: 0;
    transform: translateX(0) translateY(-20px);
    font-size: 12px;

    &:after{
      display: none;
    }

    &-item{
      height: 30px;
      color: #3A424F !important;

      span{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left: 0;
      }

      &:hover{
        background: #dee2ed;
      }
    }
  }

  &_pages{
    width: 80px;
  }

  &_item{
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background: #f5f6fa;
    border: 1px solid $bgcontrols;

    &.vis{
      border-color: #80a8ff;
    }

    .material-icons{
      color: #80a8ff;
    }
  }

  &_empty{
    background: $bgcontrols;
    .select-inner{
      color: #BFC5D1;
      font-size: 14px;
      font-weight: 300;
    }
  }
}
.grid-item_multiselect{
  padding: 5px 50px !important;
}
.select2-selection{
  outline: thin;
}
.select2-results__options{
  font-weight: 100 !important;
  padding: 0 0 0 4px;
  margin: 4px 4px 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.select2-container{
  display: block;
  width: auto !important;
  &--focus{
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  }
}
.select2-dropdown{
  left: 0 !important;
  border: 1px solid $main !important;
  border-top: none !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  background-color: #f6f9ff !important;
} 
.select2-container--default .select2-selection--multiple{
  min-height: 70px;
  background-color: #f6f9ff !important;
  border-color: $main;
  border-radius: 1px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple{
  border: 1px solid $main !important;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple{
  border-bottom: none !important;
}
.select2-selection__choice{
  height: 36px;
  background-color: #FFF !important;
  border: 1px solid #C4CAD5 !important;
  padding: 8px 15px 10px 10px !important;
  color: $main;
  margin: 6px !important;
  border-radius: 1px !important;
  &__remove{
    margin-right: 15px !important;
    color: #C4CAD5 !important;
    &:hover{
      color: #9fa4b5 !important;
    }
  }
}
.select2-container--default .select2-results__option{
  background-color: #f6f9ff !important;
}
.select2-container--default .select2-results__option--highlighted{
  background-color: $main !important;
}
.select2-container-multi{
  display: block;
  width: auto !important;
  margin: 0 50px;

  .select2-choices{
    min-height: 70px;
    background-color: #F5F6FA !important;
    background-image: none !important;
    border-color: $main !important;

    .select2-search-choice{
      height: 36px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #C4CAD5;
      border-radius: 1px;
      display: flex;
      align-items: center;
      padding: 10px 15px 10px 30px;
      color: $main;
      font-size: 14px;
      font-weight: 300;
      margin: 6px;
      box-shadow: 0 0 0;

      .select2-search-choice-close{
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
        color: #FC738D;
        left: 10px;
        top: 10px;

        &:after {
          content: "";
          display: block;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-sizing: border-box;
          z-index: 9;
          border: 0;
          border-radius: 50%;
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}

.grid-item{
  &_select{
    & .select2{
      &-container{
        &--default{
          height: 30px !important;
          line-height: 29px !important;
          cursor: pointer !important;
          padding-right: 25px !important;
          position: relative !important;
          font-weight: 300 !important;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
          border-radius: 0 !important;
          background-color: #f6f9ff !important;
          border: 1px solid #f6f9ff !important;
          border-bottom: none !important;
        }
        &--open{
          & .select2-selection__arrow{
            & b{
              border-color: transparent transparent #80a8ff transparent !important;
            }
          }
          border-color: $main !important;
        }
      }
      &-selection{
        &--single{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          display: block;
          border: none !important;
          background-color: transparent !important;
        }
        &__arrow{
          & b{
            border-color: #80a8ff transparent transparent transparent !important;
          }
        }
      }
    }
  }
}
.select2-drop{
  background-color: #f5f6fa !important;
}

.field-tours-cities,
.field-tours-categories{
  .help-block{
    margin: 0;
  }
}