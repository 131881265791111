@import '../var';

.accordion{


  &__title{
    background-color: #dee2ed;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    height: 46px;
    border-bottom: 1px solid #dee2ed;
    position: relative;
    min-height: 50px;

    &-toggle{
      position: absolute;
      top: 50%;
      margin-top: -18px;
      left: 10px;
      display: flex;

      &_closed{
        display: none;
      }

      .material-icons{
        color: #80A8FF;
      }
    }

    &-text{
      color: $main;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      margin:0 0 0 44px;
      flex: 0 0 80%;

      &--clear{
        margin-left: 20px;
      }
    }
  }

  &.closed{

    .accordion__title-toggle{
      display: none;

      &_closed{
        display: flex;
      }
    }
  }

  &__body{
    //box-shadow: inset 4px 0 0 #80A8FF;
    //border-left: 4px solid #80A8FF;
  }

  &__grid{
    padding: 12px 80px 12px 50px;
    border-bottom: 1px solid #dee2ed;
    display: flex;
    align-items: flex-start;

    &-desc{
      flex: 0 0 286px;
      color: #3A424F;
      font-size: 16px;
      font-weight: 300;
      padding: 7px 50px 0 0;
      line-height: 140%;

      &_sub{
        color: #8B93A6;
        font-size: 12px;
        display: block;
        margin-top: 10px;
        line-height: 140%;
      }
    }

    &-select{
      width: 100%;
    }

    &-val{
      flex: 1 0 auto;
      max-width: calc(100% - 286px);

      .btn-radio{
        margin: 10px 0;
      }
    }

    &_between{
      justify-content: space-between;

      .accordion__grid-desc, .accordion__grid-val{
        flex: 0 0 auto;
        max-width: 100%;
      }
    }
  }

  .grid{
    &_wrap{
      flex-wrap: wrap;

    }
  }


}
