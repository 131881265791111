@import '../var';

.status-circle-dividing,
.status-circle-bright_blue,
.status-circle-purple,
.status-circle-green,
.status-circle-skyblue,
.status-circle-pink,
.status-circle-brown,
.status-circle-orange {
  &:before {
    content: "\25CF";
    font-size: 16px;
    margin: 0 4px;
    position: relative;
    top: 0.5px;
    z-index: 10;
  }
}

.status-circle-dividing {
  &:before {
    color: $dividing;
  }
}
.status-circle-orange {
  &:before {
    color: $orange;
  }
}
.status-circle-bright_blue {
  &:before {
    color: $bright_blue;
  }
}

.status-circle-purple {
  &:before {
    color: $purple;
  }
}

.status-circle-green {
  &:before {
    color: $green;
  }
}

.status-circle-skyblue {
  &:before {
    color: $sky_blue;;
  }
}

.status-circle-pink {
  &:before {
    color: $pink;
  }
}
.status-circle-brown {
  &:before {
    color: $brown;
  }
}