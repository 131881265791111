@import '../var';

.checkbox{
  width: 100px;
  height: 30px;
  cursor: pointer;
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;


  input{
    visibility: hidden;
    position: absolute;
    opacity: 0;

    &:checked + .checkbox__switcher{
      background-color: $main;
      box-shadow: inset 0 2px 4px rgba(58, 66, 79, 0.3);
    }

    &:disabled + .checkbox__switcher{
      background-color: #F5F6FA;
      box-shadow: none;
      box-shadow: 0 0 0 1px #DEE2ED inset;
      cursor: not-allowed;
    }

    &:disabled + .checkbox__switcher:after{
      box-shadow: none;
      box-shadow: 0 0 0 1px #DEE2ED inset;
      background: transparent;
      width: 24px !important;
    }

    &:checked + .checkbox__switcher:after{
      //left: 75px;
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }

    &:checked + .checkbox__switcher{
      .checkbox__text:before{
        opacity: 0;
      }
      .checkbox__text:after{
        opacity: 1;
      }
    }

    &:disabled + .checkbox__switcher{
      .checkbox__text{
        opacity: 0;
      }
    }

    & + .checkbox__switcher{
      .checkbox__text:after{
        opacity: 0;
      }
    }
  }

  &__switcher{
    width: 100%;
    height: 100%;
    position: relative;
    transition: $transition;
    border-radius: 15px;
    background-color: #F5F6FA;
    box-shadow: 0 1px 1px rgba(0,0,0,0.1);

    &:after{
      display: block;
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: #FFF;
      box-shadow: 0 4px 8px rgba(58, 66, 79, 0.3);
      border-radius: 50%;
      left: 5px;
      top: 5px;
      transition: $transition;
    }
  }

  &:active .checkbox__switcher:after{
    width: 40px;
  }

  &__text{

    width: 100%;
    height: 100%;
    position: relative;

    &:before,
    &:after{
      display: block;
      position: absolute;
      top: 7px;
      font-size: 14px;
      font-weight: 300;
      transition: $transition;
    }

    &:before{
      content: 'Нет';
      right: 27px;
      color: #BFC5D1;
    }

    &:after{
      content: 'Да';
      left: 35px;
      color: #fff;
    }
  }
}

.checkbox-group{

  display: flex;

  .checkbox{
    height: 28px;
    width: auto;
    display: flex;
    align-items: center;
    margin-left: -1px;

    &:first-child{

      .checkbox__text{
        margin: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:last-child{
      .checkbox__text{
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    &__text{
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 300;
      padding: 0 20px;
      color: #8B93A6;
      transition: $transition;
      background-color: #F5F6FA;
      border: 1px solid #DEE2ED;
      &:before, &:after{
        display: none;
      }
    }

    input:checked + .checkbox__text{
      color: #fff;
      background-color: $main;
      box-shadow: inset 0 2px 4px rgba(58, 66, 79, 0.3);
      border: 1px solid $main
    }
  }
}

.field-travelclients-sex,
#travelclients-sex,
.field-travelorders-visa,
#travelorders-visa{
  display: flex;
  margin: 0;
}
