@import '../var';

.tag{
  height: 36px;
  background-color: #F5F6FA;
  border: 1px solid #C4CAD5;
  border-radius: 1px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: $main;
  font-size: 14px;
  font-weight: 300;
  margin: 6px 12px;
  &__wrap{
    flex-wrap: wrap;
    flex-direction: row;
  }
  &__name{
    cursor: default;
    &:before{
      display: none;
    }
  }
  .icon-btn{
    .material-icons{
      font-size: 15px;
      color: #FC738D;
    }
    &:after{
      border-color: #FC738D;
    }
    &:hover::after {
      width: 26px;
      height: 26px;
    }
  }
}