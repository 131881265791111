@import '../var';

.notice-success{
  display: flex;
  flex: 0 0 100%;
  height: 48px;
  text-align: center;
  background-color: #f5f6fa;
  color: $main;
  font-weight: 100;
  font-size: 16px;
  align-items: center;
  position: relative;
  justify-content: center;
}

.notice-success{
  &__close{
    position: absolute;
    right: 12px;
    top: 12px;
  }
}