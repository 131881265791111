@import '../var';

.grid-item_image{
	& .row{
		& .col-md-12{
			display: flex;
			align-items: flex-start;
			& .btn{
				border-radius: 0 !important;
			}
		}
	}
}

@media only screen and (max-width: 1100px){
	.grid-item_image{
		& .row{
			& .col-md-12{
				display: block;
			}
		}
	}
}