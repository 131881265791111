@import '../var';

.material-button{
  background: $bgcontrols;
  border: 0;
  padding: 0;
  cursor: pointer;
  color: $main;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 1px rgba(0,0,0,0.1);
  margin-left: 10px;

  &--danger{
    color: $danger;
  }
}

.block-title{

  .button{
    // display: none;

    float: right;
    width: auto;
    border-radius: 2px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: 100;
    margin-right: 7px;
    margin-bottom: 0.5px;
    
    height: 36px;
    padding: 2px 55px 2px 20px;
    font-size: 14px;
    color: #FFF;
    position: relative;
    background-color: $btn-blue;
    border: none;
    -webkit-appearance: none;
    &:hover{
      background-color: rgba(255,255,255,0.1) !important;
      color: #fff !important;
    }

    & .material-icons{
      color: #FFF;
      border-left: 1px solid #fff;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      padding: 8px;
    }

    &--active{
      display: block;
    }
  }
}
.block-body{
  .btn{
    // display: none;

    float: right;
    width: auto;
    border-radius: 2px;
    line-height: 34px;
    text-transform: uppercase;
    font-weight: 100;
    margin-right: 7px;
    margin-bottom: 0.5px;
    
    height: 36px;
    padding: 2px 55px 2px 20px;
    font-size: 14px;
    color: #FFF;
    position: relative;
    background-color: $btn-blue;
    border: none;
    -webkit-appearance: none;
    &:hover{
      background-color: rgba(130,169,255, 0.8) !important;
      color: #fff !important;
      border: none !important;
    }

    & .material-icons{
      color: #FFF;
      border-left: 1px solid #fff;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      padding: 8px;
    }

    & .hidden{
      display: inline-block !important;
    }

    & .glyphicon{
      position: absolute;
      left: 99.5px !important;
      right: 0 !important;
      padding: 11px;
      border-left: 1px solid #fff;
      top: 0;
      cursor: pointer;
    }

    // &-small{
    //   width: 36px;
    //   padding-left: 0;
    //   padding-right: 0;
    //   & .material-icons{
    //     border-left: none;
    //   }
    // }

    &-pink{
      background-color: $pink !important;
      &:hover{
        background-color: rgba(246,132,158, 0.8) !important;
      }
    }
  }
  .btn-danger{
    float: none !important;
    // padding-right: 20px !important;
    background-color: $pink !important;
    margin-bottom: 7px;
    &:hover{
        background-color: rgba(246,132,158, 0.8) !important;
    }
    & .glyphicon{
      left: 87.5px !important;
      width: 36px;
    //   right: -10px !important;
    //   left: 0 !important;
    //   margin-left: 10px;
    }
  }
  .btn-primary{
    float: none !important;
    // padding-right: 20px !important;
    & .glyphicon{
      left: 87.5px !important;
      width: 36px;
    //   right: -10px !important;
    //   left: 0 !important;
    //   margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .block-title{
    .button{
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 36px;
      text-indent: 9999px;
      font-size: 0;
      & .material-icons{
        border: none;
      }
    }
  }
  .block-body{
    .btn{
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 36px;
      text-indent: 9999px;
      font-size: 0;
      & .material-icons{
        border: none;
      }
      & .glyphicon{
        left: 0 !important;
        right: 0 !important;
        padding: 11px;
        border-left: none;
        font-size: 14px;
        text-indent: 0;
      }
    }
    .btn-danger{
      text-indent: 0;
      font-size: 14px;
      & .glyphicon{
        background-color: $pink;
        margin-left: 0;
      }
    }
    .btn-primary{
      text-indent: 0;
      font-size: 14px;
      & .glyphicon{
        background-color: $btn-blue;
        margin-left: 0;
      }
    }
  }
}